export const markets = [
  { id: 1, value: 1, label: "Pasar Penuin" },
  { id: 2, value: 2, label: "Pasar Nagoya Baru Tradisional" },
  { id: 3, value: 3, label: "Pasar Pujabahari" },
  { id: 4, value: 4, label: "Pasar Kaget Jodoh" },
  { id: 5, value: 5, label: "Pasar Pagi Tos 3000" },
];

export const groceries = [
  { id: 1, value: 1, label: "Beras 1KG" },
  { id: 2, value: 2, label: "Minyak Goreng" },
  { id: 3, value: 3, label: "Bawang Merah" },
  { id: 4, value: 4, label: "Bawang Putih" },
  { id: 5, value: 5, label: "Gula" },
  { id: 6, value: 6, label: "Garam" },
  { id: 7, value: 7, label: "Daging Sapi" },
  { id: 8, value: 8, label: "Susu" },
  { id: 9, value: 9, label: "Telur" },
  { id: 10, value: 10, label: "Gas Elpiji" },
];

export const months = [
  { id: "september", value: "september", label: "September" },
  { id: "oktober", value: "oktober", label: "Oktober" },
];
