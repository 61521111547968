import React, { useState } from "react";
import { InputLabel, Select, MenuItem } from "@mui/material";

const Dropdown = ({ value, onChange, label, options, getOptionId, getOptionValue, getOptionLabel }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <InputLabel id="demo-controlled-open-select-label">{label}</InputLabel>
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        value={value}
        defaultValue=""
        label={label}
        onChange={(event) => onChange(event.target.value)}
      >
        <MenuItem value="" disabled selected>
          <em>None</em>
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={getOptionId(option)} value={getOptionValue(option)}>{getOptionLabel(option)}</MenuItem>
        ))}
      </Select>
    </>
  );
};

export default Dropdown;
