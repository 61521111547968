import React from "react";

import {
  Box,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Chip,
  Button,
} from "@mui/material";

const GroceryList = ({ information, groceries, resetFilter }) => (
  <TableContainer component={Paper}>
    <Box paddingTop={4} paddingX={2} marginBottom={2}>
      <Typography variant="h4" gutterBottom>
        Data Sembako
      </Typography>
      {information && (
        <Box display="flex" gap={1.25}>
          <Chip color="info" label={information.market} variant="filled" />
          <Chip color="info" label={information.grocery} variant="filled" />
          <Chip
            color="info"
            label={`Bulan ${information.month}`}
            variant="filled"
          />
          <Button
            variant="text"
            size="small"
            color="error"
            onClick={resetFilter}
          >
            Reset Filter
          </Button>
        </Box>
      )}
    </Box>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Toko</TableCell>
          <TableCell>Harga&nbsp;(rupiah)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {groceries.length < 1 && (
          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>{"No Data Found."}</TableCell>
            <TableCell>{""}</TableCell>
          </TableRow>
        )}
        {groceries.map((grocery) => (
          <TableRow
            key={grocery.shop}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>{grocery.shop}</TableCell>
            <TableCell>{grocery.price}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default GroceryList;
