import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import axios from "axios";

import GroceryPredictor from "./components/Grocery/GroceryPredictor";
import GroceryList from "./components/Grocery/GroceryList";
import { informationMapper } from "./utils/grocery.mapper";

const App = () => {
  const [groceries, setGroceries] = useState([]);
  const [information, setInformation] = useState(null);
  const [loading, setLoading] = useState(false);

  const resetFilter = () => {
    setGroceries([]);
    setInformation(null);
    setLoading(false);
  };

  const handlePredict = async (groceryPayload) => {
    setInformation(informationMapper(groceryPayload));
    try {
      setLoading(true);
      const groceryResponse = await axios.post(
        `${process.env.REACT_APP_WEB_SERVER}/api/v1/predict`,
        groceryPayload
      );
      if (groceryResponse.status !== 200)
        throw new Error("Unable to predict grocery market price.");
      setGroceries(groceryResponse.data);
    } catch (error) {
      console.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    document.title = "Grocery Market Prediction";
  }, []);

  return (
    <Box padding={14} display="flex" flexDirection="column" gap={5}>
      <GroceryPredictor onPredict={handlePredict} loading={loading} />
      <GroceryList
        information={information}
        groceries={groceries}
        resetFilter={resetFilter}
      />
    </Box>
  );
};

export default App;
