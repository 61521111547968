import React, { useState } from "react";
import { Card, CardHeader, CardContent, FormControl } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import QueryStats from "@mui/icons-material/QueryStats";

import Dropdown from "../UI/Dropdown";
import { markets, groceries, months } from "../../data/data";

const GroceryPredictor = ({ loading, onPredict }) => {
  const [market, setMarket] = useState(markets[0].value);
  const [grocery, setGrocery] = useState(groceries[0].value);
  const [month, setMonth] = useState(months[0].value);

  const handleSubmit = (event) => {
    event.preventDefault();
    onPredict({ pasar: market, barang: grocery, bulan: month });
  };

  return (
    <Card sx={{ boxShadow: 2 }}>
      <CardHeader title="Grocery Market Prediction" />
      <CardContent>
        <form style={{ display: "flex", gap: 20 }} onSubmit={handleSubmit}>
          <FormControl required color="primary" sx={{ flex: 1, minWidth: 120 }}>
            <Dropdown
              options={markets}
              label="Pasar"
              value={market}
              onChange={setMarket}
              getOptionId={({ id }) => id}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </FormControl>
          <FormControl required color="primary" sx={{ flex: 1, minWidth: 120 }}>
            <Dropdown
              options={groceries}
              label="Sembako"
              value={grocery}
              onChange={setGrocery}
              getOptionId={({ id }) => id}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </FormControl>
          <FormControl required color="primary" sx={{ flex: 1, minWidth: 120 }}>
            <Dropdown
              options={months}
              label="Bulan"
              value={month}
              onChange={setMonth}
              getOptionId={({ id }) => id}
              getOptionLabel={({ label }) => label}
              getOptionValue={({ value }) => value}
            />
          </FormControl>
          <LoadingButton
            type="submit"
            color="info"
            variant="contained"
            loading={loading}
            loadingPosition="end"
            endIcon={<QueryStats />}
          >
            Predict
          </LoadingButton>
        </form>
      </CardContent>
    </Card>
  );
};

export default GroceryPredictor;
